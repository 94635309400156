<template>
	<div
		v-if="feature"
		class="flex flex-col items-center justify-end px-2 py-4 mx-4 text-center lg:mx-12"
	>
		<component
			:is="feature.icon"
			v-if="feature.icon"
			class="w-24 h-24 my-4"
		/>
		<h3 class="whitespace-nowrap md:text-base lg:text-xl">
			{{ feature.heading }}
		</h3>
		<p
			class="max-w-sm min-h-16 md:h-16 md:text-sm lg:text-base"
			v-html="feature.description"
		/>
	</div>
</template>

<script setup lang="ts">
import { toRefs, computed, defineAsyncComponent } from 'vue'
import type { PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { GIGABIT_INTERNET, HOUSE, RECEIPT } from '@/components/home/types'
import type { Icon, Feature } from '@/components/home/types'

const { t } = useI18n({
	useScope: 'global'
})

const props = defineProps({
	icon: {
		type: String as PropType<Icon>,
		required: true
	}
})

const { icon } = toRefs(props)

const feature = computed<Feature>(() => {
	switch (icon.value) {
	case GIGABIT_INTERNET:
		return {
			icon: defineAsyncComponent(() => import('@/components/icon/Speed.vue')),
			heading: t('home-icons.gigabit-internet.heading'),
			description: `<span>${t('home-icons.gigabit-internet.description-1')}<br class='md:hidden'>${t('home-icons.gigabit-internet.description-2')}</span>`
		}
	case HOUSE:
		return {
			icon: defineAsyncComponent(() => import('@/components/icon/House.vue')),
			heading: t('home-icons.house.heading'),
			description: `<span>${t('home-icons.house.description-1')}<br class='md:hidden'>${t('home-icons.house.description-2')}</span>`
		}
	case RECEIPT:
		return {
			icon: defineAsyncComponent(() => import('@/components/icon/Money.vue')),
			heading: t('home-icons.receipt.heading'),
			description: `<span>${t('home-icons.receipt.description-1')}<br class='md:hidden'>${t('home-icons.receipt.description-2')}</span>`
		}
	default:
		return {
			icon: defineAsyncComponent(() => import('@/components/icon/Speed.vue')),
			heading: 'Default Heading',
			description: '<span>Default<br class="md:hidden">description.</span>'
		}
	}
})
</script>
