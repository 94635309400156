<template>
	<div class="w-full pt-4 mx-auto space-y-12 md:space-y-16">
		<div class="flex flex-col justify-between w-full -mb-8 component-container md:flex-row md:mb-0">
			<HomeIcon
				v-for="icon in icons"
				:key="icon"
				:icon="icon"
			/>
		</div>
		<div class="grid grid-cols-1 gap-12 pb-8 component-container">
			<LearnMore
				v-for="(section, index) in sections"
				:key="section.title"
				:data="section"
				:position="getPosition('right', index)"
				:lazy-load="index ? true : false"
			/>
		</div>
		<LazyCarouselTestimonial />
		<LazyFaq
			class="px-8 pb-16 component-container lg:pt-8"
			:faqs="faqs"
		/>
		<LazyCarouselBlog />
	</div>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import {
	COMPANY_NAME,
	BASE_URL,
	STORYBLOK_VERSION
} from '@/constants/index.js'
import {
	JSON_LD_LOGO,
	JSON_LD_ORGANIZATION_CONTACT_POINT,
	JSON_LD_ORGANIZATION_SAME_AS,
	JSON_LD_ORGANIZATION_ADDRESS,
	JSON_LD_ORGANIZATION_AREA_SERVED,
	JSON_LD_HAS_OFFER_CATALOG
} from '@/constants/json-ld'
import { HOMEPAGE_ICONS } from '@/components/home/types'
import type { Icons } from '@/components/home/types'
import type { FaqProps } from '@/components/faq/types'
import { mapLearnMore, getPosition } from '@/utils/learnMoreMap'
import { useStoryblokWithErrorReporting } from '@/composables/useStoryblok'

const { getStory } = useStoryblokWithErrorReporting()

const { tm, t, locale } = useI18n({
	useScope: 'global'
})

useHead({
	title: t('meta-title'),
	meta: [
		{ name: 'description', content: t('meta-description', { COMPANY_NAME }) },
		{ name: 'url', content: BASE_URL }
	],
	link: [
		{
			rel: 'icon',
			type: 'image/x-icon',
			href: '/favicon.ico'
		}
	]
})

definePageMeta({
	showAddressSearch: true,
	useDefaultHeroContent: true
})

const story = await getStory('home', {
	version: STORYBLOK_VERSION,
	language: locale.value
})

const { title, description } = story.value?.content?.metatags

useSeoMeta({
	title: () => title,
	ogTitle: () => title,
	description: () => description,
	ogDescription: () => description,
	ogImage: () => '/images/logo.svg',
	ogUrl: () => BASE_URL,
	twitterTitle: () => title,
	twitterDescription: () => description,
	twitterImage: () => '/images/logo.svg',
	twitterCard: () => 'summary_large_image'
})

const sections = mapLearnMore(story.value?.content?.learnMoreContent)

const faqs = tm('faqs') as FaqProps[]

const icons = computed((): Icons => HOMEPAGE_ICONS)

useJsonld({
	'@context': 'https://schema.org',
	'@type': 'Organization',
	name: COMPANY_NAME,
	url: BASE_URL,
	logo: JSON_LD_LOGO,
	description: t('meta-description', { COMPANY_NAME }),
	contactPoint: JSON_LD_ORGANIZATION_CONTACT_POINT,
	sameAs: JSON_LD_ORGANIZATION_SAME_AS,
	address: JSON_LD_ORGANIZATION_ADDRESS,
	areaServed: JSON_LD_ORGANIZATION_AREA_SERVED,
	hasOfferCatalog: JSON_LD_HAS_OFFER_CATALOG
})
</script>
