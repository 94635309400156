// Constants representing different icons.
const GIGABIT_INTERNET = 'gigabitInternet'
const HOUSE = 'house'
const RECEIPT = 'receipt'

// Icon types
type GigabitInternet = typeof GIGABIT_INTERNET
type House = typeof HOUSE
type Receipt = typeof RECEIPT

// Union and tuple types for the icons.
type Icon = GigabitInternet | House | Receipt
type Icons = [ GigabitInternet, House, Receipt ]

// Constant for homepage icons.
const HOMEPAGE_ICONS: Icons = [ GIGABIT_INTERNET, HOUSE, RECEIPT ]

// Interface for a feature.
interface Feature {
	icon: Component
	heading: string
	description: string
}

interface HeroContentProps {
	title?: string
	subtitle?: string
	description?: string
}

export {
	type Feature,
	type HeroContentProps,
	type Icon,
	type Icons,
	type GigabitInternet,
	type House,
	type Receipt,
	GIGABIT_INTERNET,
	HOUSE,
	RECEIPT,
	HOMEPAGE_ICONS
}
